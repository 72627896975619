<template lang="pug">
  include ../mixins/_mixins.pug
  section.c-home__wrap
    .c-home.container
      .c-home__logo
        +icon('OnlyDiamondLogo2')
      .c-home__slogan СДЕЛАЕМ МИР КРАСИВЕЕ
      .c-home__title
        +icon('namediamond')
      <router-link class="c-btn c-btn__home" to="/constructor/">
        .c-btn__arr.c-btn__arr-left
          .c-btn__arr-line.c-btn__arr-line_v
          .c-btn__arr-line.c-btn__arr-line_h
        .c-btn__arr.c-btn__arr-right
          .c-btn__arr-line.c-btn__arr-line_v
          .c-btn__arr-line.c-btn__arr-line_h
        span.c-btn__name КОНСТРУКТОР
      </router-link>
</template>

<script>
export default {
  name: 'home',
  components: {},
  metaInfo: {
    title: 'Бриллианты ручной работы | OnlyDiamond',
    meta: [
      {
        name: 'description',
        content:
          'Бриллианты ручной работы в компании OnlyDiamond. Продаем как готовые бриллианты, так и украшения ручной работы. Вся продукция сертифицирована.  Заказать бриллиант или получить консультацию +7 (921) 092-00-04',
      },
    ],
  },
}
</script>
